import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
  return axiosInstance({
    url: "/campaign/list?" + data,
    method: "post",
    data: window.location.href.includes("campaignRequests")
      ? { requestStatus: 1 }
      : null,
  });
}

export function getById(id: string) {
  return axiosInstance({
    url: "/campaign?campaignId=" + id,
    method: "get",
  });
}

export function getAssetsById(id: string) {
  return axiosInstance({
    url: "/campaign/assets?campaignId=" + id,
    method: "get",
  });
}

export function updateRequestStatusById(campaignId: any, requestStatus: any) {
  return axiosInstance({
    url: "/campaign/updateRequest",
    method: "post",
    data: { campaignId, requestStatus },
  });
}

export const apiMethod = {
  list,
  getById,
  getAssetsById,
  updateRequestStatusById,
};
