import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/campaigns";
import { routes } from "routes";
import TableComponent from "components/table";

const Campaigns = () => {
  const columns = [
    
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Days",
      dataIndex: "days",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Status",
      dataIndex: "campaignStatusName",
      sorter: false,
      dataType: "text",
    },
    { title: "Actions", dataType: "actions", width: 96 },
  ];

  return (
    <PageContainer
      title={window.location.href.includes("campaignRequests") ?  "Campaign Requests" : "Campaigns"}
    >
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={columns}
        viewRoute={window.location.href.includes("campaignRequests") ? routes.campaignRequests.view : routes.campaigns.view}
      />
    </PageContainer>
  );
};

export default Campaigns;
